﻿@use '@angular/material' as mat;
@import "main/util/index.scss";

/* ==========================================================
	SETUP
	change base colors in variables.scss to customize theme
=========================================================== */

// palettes
$pcode-palette-primary: (
	50: lighten($pcode-color-primary, 20%),
	100:lighten($pcode-color-primary, 15%),
	200: lighten($pcode-color-primary, 10%),
	300: lighten($pcode-color-primary, 5%),
	400: lighten($pcode-color-primary, 5%),
	500: $pcode-color-primary,
	600: darken($pcode-color-primary, 5%),
	700: darken($pcode-color-primary, 10%),
	800: darken($pcode-color-primary, 15%),
	900: darken($pcode-color-primary, 20%),
	A100: #a7ffeb,
	A200: #64ffda,
	A400: #1de9b6,
	A700: #00bfa5,
	contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity)
);
$pcode-palette-accent: (
	50: lighten($pcode-color-accent, 20%),
	100:lighten($pcode-color-accent, 15%),
	200: lighten($pcode-color-accent, 10%),
	300: lighten($pcode-color-accent, 5%),
	400: lighten($pcode-color-accent, 5%),
	500: $pcode-color-accent,
	600: darken($pcode-color-accent, 5%),
	700: darken($pcode-color-accent, 10%),
	800: darken($pcode-color-accent, 15%),
	900: darken($pcode-color-accent, 20%),
	A100: #ff80ab,
	A200: #ff4081,
	A400: #f50057,
	A700: #c51162,
	contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity)
);
$pcode-palette-warn: (
	50: lighten($pcode-color-warn, 20%),
	100:lighten($pcode-color-warn, 15%),
	200: lighten($pcode-color-warn, 10%),
	300: lighten($pcode-color-warn, 5%),
	400: lighten($pcode-color-warn, 5%),
	500: $pcode-color-warn,
	600: darken($pcode-color-warn, 5%),
	700: darken($pcode-color-warn, 10%),
	800: darken($pcode-color-warn, 15%),
	900: darken($pcode-color-warn, 20%),
	A100: #ff8a80,
	A200: #ff5252,
	A400: #ff1744,
	A700: #d50000,
	contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity)
);

// theme configuration
$pcode-theme-primary: mat.m2-define-palette($pcode-palette-primary, 500);
$pcode-theme-accent: mat.m2-define-palette($pcode-palette-accent, 500);
$pcode-theme-warn: mat.m2-define-palette($pcode-palette-warn, 500);

// theme setup
$pcode-theme: mat.m2-define-light-theme((color: (primary: $pcode-theme-primary, accent: $pcode-theme-accent, warn: $pcode-theme-warn), typography: mat.m2-define-typography-config(), density: 0));
@include mat.all-component-themes($pcode-theme);

a.logo-box {
	width: 190px;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-logo li img {
	border-radius: 50%;
	width: 28px;
	height: 28px !important;
}

.mat-sidenav-content,
.mat-drawer-container,
.mat-sidenav-content,
.mat-drawer-content {
	background-color: #eef5f9 !important;
	overflow: visible !important;
	overflow: initial !important;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-pills>li>a>i {
	font-size: 1.28rem;
}

.app_sidebar-menu-collapsed #app_main-menu-wrapper .nav-pills {
	padding-top: 0;
}

body #app_wrapper #content_wrapper .content .header-wrapper {
	h1 {
		margin-bottom: 0;
		padding: 20px 15px;

		small {
			display: block;
			margin-top: 10px;
			color: #fff;
			font-weight: normal;
			font-size: 12px;
			line-height: 12px;
		}
	}

	&.header-md {
		height: 116px;
	}

	&.header-tabs {
		height: 86px;
	}

	.ticket-header-details {
		position: absolute;
		right: 20px;
		bottom: 40px;
		text-align: right;

		span.header-badge {
			margin-left: 10px;
			margin-top: 10px;
			display: inline-block;
			background: #fff;
			color: $page_header_bg;
			border-radius: 50px;
			font-size: .75rem;
			font-weight: 600;
			padding: 0.15rem 0.6rem;

			mat-icon {
				font-size: 18px;
				height: 18px;
				width: 18px;
				vertical-align: top;
				margin-right: 5px;
			}

			span {
				vertical-align: middle;
			}

			span.header-badge-separator {
				height: 100%;
				margin: 0 6px 0;
				border-left: 1px dashed $page_header_bg;
			}

			&.disabled-badge {
				opacity: .75;
			}
		}

		button {
			background-color: #fff;
			font-size: .75rem;
			font-weight: 600;
			padding: 0.15rem 0.6rem;
			margin-left: 10px;
			margin-top: 10px;
			color: $page_header_bg;
			height: 0.75;
		}
	}
}

.content .header-wrapper.header-md.overlay+.content-body {
	padding-top: 86px;
}

#footer_wrapper {
	width: 100%;
}

#header_wrapper,
#header_wrapper #header {
	padding-left: 5px;
	padding-right: 5px;
}

.fullWidth2-wrapper .header-wrapper smd-fab-speed-dial {
	position: absolute;
	right: 45px;
	top: 58px;
}

.fullWidth3-wrapper .header-wrapper smd-fab-speed-dial {
	position: absolute;
	right: 45px;
	top: 58px;
}

#app_topnavbar-wrapper .navbar .navbar-nav li.nav-item ul.dropdown-menu.dropdown-menu--langs li a {
	padding: 10px;
}

#content_outer_wrapper #content_wrapper.card-overlay #header_wrapper.header-md+#content {
	padding: 80px 20px 0;
}

.card-heading--search {
	background: #fafafa;
	border-bottom: 1px solid #efefef;

	.form-group {
		margin-top: 0;
	}
}

#header_wrapper.header-md #header h1 {
	display: inline-block;
}

.breadcrumb {
	padding: 8px 15px 8px 10px;
	display: inline;
	vertical-align: text-top;
}

form {

	.btn.disabled,
	.btn:disabled,
	button.disabled,
	button:disabled {
		background-color: #bbb !important;
	}
}

.mat-mdc-icon-button.disabled mat-icon {
	color: #bbb !important;
}

ul.card-actions.icons.right-top {
	top: -28px;
}

.card-actions.icons.icons--custom>a,
.card-actions.icons.icons--custom>a:hover,
.card-actions.icons.icons--custom>li>a,
.card-actions.icons.icons--custom>li>a:hover {
	height: 56px;
	min-width: 56px;
	width: 56px;
	line-height: 56px;
	color: #fff;
}

.card .card-heading.card-heading--filter {
	background: #fafafa;
	border-bottom: 1px solid #ddd;
	margin-bottom: 30px;
	@include box-shadow(0, 2px, 6px, rgba(0, 0, 0, 0.1));

	.form-group {
		margin-top: 0;
	}
}

.card {
	.card-block {
		padding: 1rem;
	}

	.card-footer {
		border-top: 4px solid #EEF5F9;

		button {
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.table-card {
		.card-block {
			padding: 0;
		}
	}
}

.error {
	position: relative;
	top: -1rem;
	left: 0rem;
	font-size: 0.8rem !important;
	color: #f66c61 !important;
	-webkit-transform: translateY(0%);
	-ms-transform: translateY(0%);
	-o-transform: translateY(0%);
	transform: translateY(0%);
}

.mat-form-field-suffix svg,
.mat-mdc-icon-button svg {
	display: inline;
}

.pc-notification {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: -44px;
	width: 100%;
	left: 0;
	z-index: 9999;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;

	>.inner {
		max-width: 90%;
		background: #676767;
		padding: 10px 30px;
		color: #fff;
		text-align: center;
	}

	&.success>.inner {
		background: #28a745;
	}

	&.danger>.inner {
		background: #dc3545;
	}

	&.active {
		bottom: 0;
	}
}

.pc-form-filters {
	padding: 1rem;
	border-bottom: 4px solid #e3ecf7;

	.mdc-floating-label {
		font-size: 14px;
		top: 1.5em;
	}

	.form-group {
		margin: 0;
	}

	.buttons-group {
		display: flex;

		button {
			min-width: min-content;
			margin: 0 15px 0 0;
			align-self: center;

			>i {
				margin-right: 10px;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.pc-actions-btn {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 99;
}

.content-body {
	.form-group>label {
		text-align: right;
	}
}

.content-body {
	.card {
		.card-header {
			.card-actions {
				position: absolute;
				right: 15px;
				bottom: -28px;
			}
		}
	}
}

.form-horizontal {
	label {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;

		&.required {
			position: relative;

			&:after {
				display: inline-block;
				content: "*";
				color: #d83939;
				font-size: 11px;
				line-height: 11px;
				position: absolute;
				right: -12px;
				top: 6px;
			}
		}
	}
}

.modal {
	.modal-header {
		.modal-title {
			display: flex;

			i {
				font-size: inherit;
				line-height: inherit;
			}
		}

		.close {
			background: none;
			color: #fff;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			display: block;
			width: 32px;
			height: 32px;
			padding: 0;
		}
	}

	.modal-dialog.modal-xl {
		margin: 2% auto;
	}
}

/* toaster */
.toast-container {
	font-family: $font-family-body;
	font-size: 14px;
	font-weight: normal;

	&.toast-bottom-right {
		right: 30px;
		bottom: 4px;
	}

	.toast,
	.toast:hover {
		@include border-radius(2px);
		-webkit-box-shadow: none;
		box-shadow: none;

		.toast-title {
			font-weight: normal;
		}

		.toast-message {
			font-size: 12px;
		}
	}

	.toast-success {
		background-color: #5bb944;
	}

	.toast-error {
		background-color: #ff5252;
	}

	.toast-info {
		background-color: #40c4ff;
	}

	.toast-warning {
		background-color: #ffab40;
	}
}

.permissions-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;

	>li {
		display: block;
		border-bottom: 1px dashed #ddd;
		padding-bottom: 10px;
		margin-bottom: 10px;

		strong {
			display: block;
			margin-bottom: 10px;
		}

		ul {
			display: block;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: block;
			}
		}
	}
}

.form-control:disabled,
.form-control[readonly] {
	cursor: default;
	opacity: 0.5;
}

.breadcrumb {
	display: block;
	margin: 0 0 0 15px;
	padding: 0;

	li.breadcrumb-item {
		display: inline-block;

		+.breadcrumb-item {
			padding-left: 0;

			&::before {
				display: inline-block;
				padding-right: .5rem;
				color: #fff;
				content: "/";
			}
		}

		a {
			color: #fff;
		}
	}
}

.form-actions {
	position: relative;
	margin-top: 20px;
	padding-top: 20px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		border-top: 1px solid #ddd;
		z-index: -2;
	}

	button {
		margin-right: 1rem;
		margin-left: 0;

		&:last-child {
			margin-right: 0;
		}
	}
}

.pointer {
	cursor: pointer;
}

.list-group.list-group--divided .list-group-item {
	border-bottom: 1px dashed #ddd;

	&:last-child {
		border-bottom: 0;
	}
}

div[role=progressbar][aria-busy=true] {
	padding-top: 3px;
}

div[role=progressbar][aria-busy=true]::after,
div[role=progressbar][aria-busy=true]::before,
header [role=progressbar][aria-busy=true]::after,
header [role=progressbar][aria-busy=true]::before {
	height: 3px;
}

#ajax-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 3px;
	z-index: 999;
}

.cdk-overlay-container {
	z-index: 9999 !important;
}

.appBackdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	background: rgba(131, 151, 172, 0.7);
	z-index: 110;
	-webkit-animation: fadeInFromNone 250ms ease-out;
	animation: fadeInFromNone 250ms ease-out;
}

.modal-backdrop {
	z-index: 99;
}

.modal-dialog.modal-xl {
	width: 1400px;
	max-width: 95%;
}

#app_topnavbar-wrapper .hidden-sm-down {
	display: block !important;
}

body #app_wrapper #app_topnavbar-wrapper .navbar-nav li a.notify {
	>sup {
		display: block;
		position: absolute;
		width: 15px;
		height: 15px;
		line-height: 15px;
		font-size: 10px;
		top: 15px;
		right: 5px;
		border-radius: 100%;
	}

	&.notify-active,
	&.notify-active:hover {
		sup {
			background: #FF6F00;
			color: #fff;
		}
	}
}


/* tabs */
.mat-mdc-tab-header {
	background: #fff;
}

.mat-mdc-tab.mdc-tab {
	min-width: 160px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
	border-color: #ec407a;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
	color: inherit;
}

.content mat-tab-header .mat-mdc-tab-label-container {
	padding-left: 30px;
}

.content mat-tab-group.header-not-padded mat-tab-header .mat-mdc-tab-label-container {
	padding-left: 0px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: #ec407a !important;
}

.mat-mdc-tab-label {
	.mat-mdc-tab {
		font-family: $font-family-body;
	}

	&.mat-tab-label-active {
		opacity: 1;
	}
}

/* dialogs */
.pcode-dialog-panel {
	.mat-mdc-dialog-container {
		.mat-mdc-dialog-title {
			color: #516073;
			display: flex;
			margin-bottom: 0;
			font-size: 20px;
			line-height: 20px;
			font-family: $font-family-header;
			width: 100%;
			padding: 10px 20px;
			background: #E3ECF7;
			font-weight: normal;

			>* {
				align-self: center;
			}

			&.primary-dialog {
				background: $pcode-color-accent;
				color: #fff;
			}

			&.error-dialog {
				background: $mais-error-700;
				color: #fff;
			}

			&.warn-dialog {
				background: $mais-warning-700;
				color: #fff;
			}

			&.success-dialog {
				background: $mais-success-500;
				color: #fff;
			}
		}

		.mat-mdc-dialog-content {
			margin: 0;
			padding: 30px 24px 15px;
			color: rgba(0, 0, 0, 0.87);

			.pc-form-filters {
				padding: 0;
				border: 0;
			}
		}

		.mat-mdc-dialog-actions {
			padding: 12px 24px 20px;
			margin-bottom: 0;
		}
	}

	@media(max-width:992px) {
		min-width: 90%;
	}
}

.light-dialog-overlay-wrapper {
	background: rgba(255, 255, 255, 0.7);
}

/* tooltip */
.mat-mdc-tooltip {
	// background: darken(#9daec4, 10%) !important;
	font-size: rem(13px) !important;
}

.popover {
	max-width: 300px;
	box-shadow: 0 5px 10px rgba(175, 188, 206, .6);
	border: 1px solid rgba(175, 188, 206, .3);

	.popover-title {
		background-color: #eef5f9;
		border-bottom: none;
		font-weight: 400;
		font-size: 16px;
		padding: 15px 10px;
	}

	.popover-content {
		padding: 9px 14px;
	}
}

//Demo only styles
.btn-equal-width .btn {
	width: 40%;
}


/* snackbar */
body {
	.mat-mdc-snack-bar-container .mdc-snackbar__surface {
		background-color: #465e75;
	}

	.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
		color: #7f98b0;
	}
}

/* table expanded row icon */
.expand-row-icon {
	cursor: pointer;
	color: #465E75;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.expanded {
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

a:not([href]):not([tabindex]) {
	text-decoration: none;
	cursor: pointer;
}

/* responsive */

@media(max-width:768px) {
	.content .header-wrapper.header-md.overlay+.content-body {
		padding-left: 10px;
		padding-right: 10px;
	}

	.fullWidth2-wrapper .content .content-body {
		padding: 10px;
	}

	.fullWidth3-wrapper .header-wrapper smd-fab-speed-dial {
		right: 15px;
		top: 35px;
	}

	.rightSidenav2-wrapper .header-wrapper .actions.fab.bottom-right {
		right: 15px !important;
	}

	div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
		padding-right: 0;
		padding-left: 0;
	}

	body #app_wrapper #content_wrapper .content .header-wrapper h1 {
		padding: 20px 0;
		text-align: center;
	}

	#footer_wrapper .footer-content {
		.pc-version {
			display: none;
		}
	}
}

@media(max-width:577px) {
	.form-horizontal .form-group label {
		text-align: left;
		padding-bottom: 0;
		display: inline-block;
		width: auto;
	}
}

/* angular 15 new */

body {
	font-family: $font-family-body;

	/* link */
	a {
		color: $text_color;
	}

	/* form fields */
	.mat-mdc-form-field.mat-form-field-appearance-fill {
		width: auto;
		display: block;

		.mdc-text-field {
			padding-left: 0;
			padding-right: 0;

			&.mdc-text-field--filled {
				background-color: transparent;

				.mdc-line-ripple {
					&:before {
						border-bottom-color: rgba(0, 0, 0, 0.12);
					}

					&:after {
						border-bottom-width: 1.5px;
					}
				}

				&.mdc-text-field--disabled {
					.mdc-line-ripple::before {
						border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
					}
				}

				.mdc-text-field__input {
					color: rgba(0, 0, 0, 0.87);
				}

				&.mdc-text-field--focused {
					.mat-mdc-form-field-focus-overlay {
						opacity: 0;
					}

					.mdc-floating-label {
						color: #3f51b5 !important;
					}
				}

				.mdc-floating-label {
					.mat-mdc-form-field-required-marker:after {
						color: #e91e63;
						margin-left: 3px;
					}
				}
			}
		}

		.mat-mdc-form-field-error-wrapper {
			left: auto;
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-form-field-hint-wrapper {
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
			.mat-mdc-form-field-infix {
				min-height: unset;
				padding-top: 24px;
				padding-bottom: 6px;
			}
		}

		&:hover {
			.mat-mdc-form-field-focus-overlay {
				opacity: 0;
			}
		}
	}

	/* radio */
	.mat-mdc-radio-button {
		.mdc-form-field {
			label {
				cursor: pointer;
				padding-left: 8px;
			}

			.mdc-radio {
				padding: 0;
			}
		}
	}

	/* checkbox */
	.mat-mdc-checkbox {
		.mdc-checkbox {
			padding: 0;

			.mdc-checkbox__background {
				top: 0;
				left: 0;
			}
		}

		label {
			padding-left: 8px;
		}
	}

	/* select */
	.mat-mdc-select {
		.mat-mdc-select-arrow-wrapper {
			transform: translateY(-2px);
		}

		.mat-mdc-select-trigger {
			.mat-mdc-select-value {
				color: rgba(0, 0, 0, 0.87) !important;
			}
		}

	}

	/* button */
	.mdc-button {
		padding-left: 16px;
		padding-right: 16px;
	}

	/* list */
	.mdc-list {
		font-family: $font-family-body;
	}

	/* datepicker */
	mat-datepicker-toggle {
		width: 24px;
		height: 24px;

		.mat-mdc-icon-button.mat-mdc-button-base {
			padding: 0;
			width: inherit;
			height: inherit;
			margin-top: 10px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

//Custom
.spinner {
	animation: spin 2s linear infinite;
}

.table-legend {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;

	>li {
		display: flex;
		margin-right: 20px;

		>* {
			align-self: center;
		}

		>mat-icon {
			margin-right: 10px;
			font-size: 14px;
			width: inherit;
			height: inherit;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}