@import "../util/index.scss";

ul.actions
{
	position: absolute;
	list-style: none;
	padding: 0;
	margin: 0;

	&.icons > li > a:before
	{
		left: 0;
		top: 0;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform: scale3d(0, 0, 0);
		-moz-transform: scale3d(0, 0, 0);
		-ms-transform: scale3d(0, 0, 0);
		-o-transform: scale3d(0, 0, 0);
		transform: scale3d(0, 0, 0);
		-webkit-transition: all;
		-o-transition: all;
		transition: all;
		-webkit-transition-duration: 250ms;
		transition-duration: 250ms;
		background-color: rgba(0, 0, 0, .1);
		z-index: 0;
		border-radius: 50%;
		opacity: 0;
		filter: alpha(opacity=0);
	}

	&.top-left
	{
		top: 15px;
		left: 10px;
	}

	&.top-right
	{
		top: 15px;
		right: 10px;
	}

	li
	{
		display: inline-block;

		a
		{
			i
			{
				font-size: rem(20px);
			}
		}
	}
}

.content
{
	.header-wrapper
	{
		ul.actions
		{
			&.icons > li > a
			{
				width: 35px;
				height: 35px;

				i
				{
					font-size: rem(23px);
				}
			}

			&.top-left
			{
				top: 17px;
				left: 20px;
			}

			&.top-right
			{
				top: 17px;
				right: 20px;
			}
		}
	}
}

.actions.top-right
{
}

.actions.top-left
{
}

.actions.icons > a,
.actions.icons > li > a
{
	width: 30px;
	height: 30px;
	display: inline-block;
	text-align: center;
	padding-top: 6px;
	position: relative;
}

.actions.icons > a:hover,
.actions.icons > li > a:hover
{
	color: #3e4c5e;
}

.actions.icons.lg > a,
.actions.icons.lg > li > a
{
	font-size: rem(16px);
}

.actions.icons.lg > a:before,
.actions.icons.lg > li > a:before
{
	top: 3px;
}

.actions.icons > li.open > a > i,
.actions.icons.open > a > i
{
	color: #000;
}

.actions.icons.alt-actions > li > a > i
{
	color: rgba(255, 255, 255, .9);
}

.actions.icons.alt-actions > li > a:hover > i,
.actions.icons.alt-actions > li.open > a > i
{
	color: #fff;
}
