@import "./util/index.scss";

//
// Base
// --------------------------
*
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
html
{
	height: 100%;
	min-width: 100%;
	min-height: 100vh !important;
	font-size: 100%;
	max-width: 100%;
	overflow-x: hidden;
}

body
{
	padding: 0;
	margin: 0;
	display: block !important;
	font-family: $font-family-body;
	font-weight: 400;
	line-height: em(20);
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	background-color: #eef5f9;
	color: $text_color;
}

a:link,
a:visited
{
	text-decoration: none;
	outline: none;
	@include transition(all 250ms);
}

a:active,
a:hover
{
	outline: none;
	text-decoration: none;
}

*,
:focus,
:active
{
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6
{
	font-family: $font-family-header;
	font-weight: 300;
	line-height: 1.6rem;
}

h1
{
	font-size: rem(27px);
}

h2
{
	font-size: rem(24px);
}

h3
{
	font-size: rem(20px);
}

h4
{
	font-size: rem(18px);
}

p
{
	font-size: rem(15px);
	line-height: 1.9em;
}

hr
{
	margin-top: 10px;
	margin-bottom: 10px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
	font-family: $font-family-header;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small
{
	color: #575f6c;
}

.dl-horizontal,
.dl-vertical
{
	margin-bottom: 12px;

	dt
	{
		font-weight: 600;
	}

	dt,
	dd
	{
		font-size: em(14);
	}
}

small
{
	font-size: 11px;
	font-weight: 600;
}

img
{
	max-width: 100%;
}

pre
{
	background-color: #e3ecf7;
	border: 1px solid #cdd7e2;
	color: $text_color;
	padding: 10px;
}

blockquote
{
	border-left: 5px solid #e3ecf7;
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: rem(18px);
}

canvas
{
	display: block;
	padding: 0;
	margin: 0 auto;
	max-width: 100%;
}

address
{
	line-height: 1.5em;
	font-size: em(14);
}

button
{
	outline: none !important;
}

::-webkit-input-placeholder
{
	color: rgba(96, 113, 136, .6) !important;
}

:-moz-placeholder
{
	color: rgba(96, 113, 136, .6) !important;
}

::-moz-placeholder
{
	color: rgba(96, 113, 1364, .6) !important;
}

:-ms-input-placeholder
{
	color: rgba(96, 113, 136, .6) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-input-placeholder,
::placeholder
{
	color: rgba(96, 113, 136, .6) !important;
}

button,
input,
optgroup,
select,
textarea
{
	font-family: $font-family-body;
}
