﻿@use '@angular/material' as mat;

/* =========================================
	SETUP AND CUSTOMIZATION
	change base colors to customize theme
========================================== */

$pcode-color-primary: #077A92!default;
$pcode-color-secondary: #475466!default;
$pcode-color-accent: #077A92!default;
$pcode-color-warn: #FF595A!default;

$mais-base-900: #0F1728;
$mais-base-600: #475466;
$mais-base-400: #98A1B2;
$mais-base-200: #E4E6EB;
$mais-base-100: #F2F3F6;
$mais-base-000: #FFFFFF;

$mais-interactive-900: #024C74;
$mais-interactive-800: #03688C;
$mais-interactive-700: #077A92;
$mais-interactive-200: #9DFDE4;
$mais-interactive-100: #CEFEED;

$mais-error-700: #B7192E;
$mais-warning-700: #B54707;
$mais-success-500: #21680C;

/* =========================================
	THEME VARIABLES
========================================== */
/* fonts */
$font-family-header: 'Poppins', sans-serif!default;
$font-family-body: 'Open Sans', sans-serif!default;

/* globals */
$body_bg: #eef5f9; //** background color for <body>
$text_color: #607188; //** global text color on <body>
$header_text_color: #516073; //** global text color for all h1 - h6

/* top navbar */
$app_topnavbar-wrapper_bg: #fff;
$app_topnavbar-wrapper_color: #688696; //** text color for <header id="app_topnavbar-wrapper">
$app_topnavbar-wrapper_hover_color: rgba(238, 245, 249, .6);
$navbar_header_bg: #2c3a48; //** background color for <div id="navbar_header">

/* page header */
$page_header_bg: $mais-interactive-900;
$page_header_text: #fff;

/* sidebar */
$sidebar_menu_wrapper_bg: $mais-base-900; //** background color
$sidebar_menu_active_bg: #0F1728; //** background active color
$sidebar_menu_text_color: #91a6bb; //** text active color
$sidebar_menu_active_text_color: #fff; //** text active color

/* brand colors */
$brand-primary: #28bebd !default;
$brand-success: #009688;
$brand-danger: mat.m2-get-color-from-palette(mat.$m2-red-palette, 400) !default;
$brand-warning: mat.m2-get-color-from-palette(mat.$m2-yellow-palette, 500) !default;
$brand-info: mat.m2-get-color-from-palette(mat.$m2-blue-palette, 400) !default;

/* commons */
$black-87-opacity: rgba(0, 0, 0, 0.87);
$white-87-opacity: rgba(255, 255, 255, 0.87);
