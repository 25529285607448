#footer_wrapper
{
	position: relative;
	bottom: 0;
	width: 100%;
	background: #2196f3;
	z-index: 10;

	.footer-content
	{
		text-align: left;
		padding: 2px 30px;

		p
		{
			margin: 0;
			font-size: 10px;
			color: #fff;
		}
	}
}
