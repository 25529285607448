﻿// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base
{
	:root
	{
		--tw-color-primary: #077A92;
		--tw-color-secondary: #475466;
		--tw-color-accent: #077A92;
		--tw-color-warn: #FF595A;
	}
}

// material wrap
@import "./main/util/index";
@import "./main/base";
@import "./main/cards/cards";
@import "./main/layouts/app/top-navbar";
@import "./main/layouts/app/left-sidebar";
@import "./main/layouts/app/right-sidebar";
@import "./main/layouts/page/content";
@import "./main/layouts/page/headers";
@import "./main/layouts/page/full-width-v1";
@import "./main/layouts/page/full-width-v2";
@import "./main/layouts/page/full-width-v3";
@import "./main/layouts/page/boxed-layout-v1";
@import "./main/layouts/page/boxed-layout-v2";
@import "./main/layouts/page/boxed-layout-v3";
@import "./main/layouts/page/left-sidenav-v1";
@import "./main/layouts/page/left-sidenav-v2";
@import "./main/layouts/page/left-sidenav-v3";
@import "./main/layouts/page/right-sidenav-v1";
@import "./main/layouts/page/right-sidenav-v2";
@import "./main/layouts/page/right-sidenav-v3";
@import "./main/layouts/page/footer";
@import "./main/ui/actions";

// node modules
@import "@angular/material/prebuilt-themes/indigo-pink.css";

//theme
@import "themes/theme-a.scss";

// pCode
@import './pcode.scss';
@import './custom.scss';