﻿@import "main/util/index.scss";

.mat-mdc-tab-body {
	pointer-events: none;

	&.mat-mdc-tab-body-active {
		z-index: auto !important;
		pointer-events: auto;
	}
}

// Fix posizionamento menu tinymce su modale
.tox.tox-tinymce-aux {
	z-index: 10000 !important;
}

//timepicker
ngx-material-timepicker-toggle .ngx-material-timepicker-toggle {
	padding: 2px !important;
	margin-top: 10px;

	.mat-icon {
		font-size: 22px;
		line-height: 24px;
		height: 24px;
		width: 24px;
	}
}

ngx-material-timepicker-container {

	.timepicker-backdrop-overlay {
		z-index: 9999 !important;
	}

	.timepicker-overlay {
		z-index: 10000 !important;
	}

	.timepicker-overlay ngx-material-timepicker-content .timepicker {
		--button-color: #077A92;
		--dial-background-color: #077A92;
		--dial-editable-active-color: #077A92;
		--clock-hand-color: #077A92;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

		.timepicker__header {
			padding: 10px 20px;

			ngx-material-timepicker-dial .timepicker-dial {
				.timepicker-dial__container {
					justify-content: flex-start;

					.timepicker-dial__time {
						font-size: 30px;

						.timepicker-dial__control {
							font-size: 30px;
							width: 35px;
						}
					}
				}
			}
		}

		.timepicker__main-content {
			.timepicker__body {
				padding: 15px 5px 0;
			}
		}

		.timepicker__actions {
			padding: 10px 20px;
			justify-content: space-between;
		}
	}
}

.custom-timepicker-toggle {
	font-size: 16px !important;
	line-height: 24px !important;
	color: #757575;
}

.card {
	.card-block {

		.card-divider {
			width: 100%;
			box-sizing: content-box;
			padding: 0 1rem;
			margin: 16px -1rem;
			border-top: 4px solid #EEF5F9;
		}

		.card-section-divider {
			display: flex;
			justify-content: space-between;
			box-sizing: content-box;
			height: 28.5px;
			padding: 18px 1rem 8px;
			margin: 13px -1rem 0;
			width: 100%;
			border-top: 4px solid #EEF5F9;

			.card-section-divider-title {
				span {
					font-size: 0.9rem;
					font-weight: bolder;
					font-variant: small-caps;
					letter-spacing: 0.5px;
					vertical-align: middle;
					color: $pcode-color-primary;
				}

				.mat-icon {
					font-size: 20px;
					width: 20px;
					height: 20px;
					color: $pcode-color-primary;
					vertical-align: middle;
					margin-right: 8px;
				}
			}
		}
	}
}

.user-action-section button.user-action-button {
	position: relative;
	height: 80px;
	border-radius: 8px;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 300;
	overflow: hidden;
	box-shadow: 0 3px 1px #0000001b;
	transition: all 400ms ease;

	.mat-icon {
		position: absolute;
		bottom: -1.5rem;
		right: -1.5rem;
		font-size: 6rem;
		height: 6rem;
		width: 6rem;
		line-height: 6rem;
		opacity: .2;
		margin: 0 !important;
		transition: all 400ms ease;
	}

	&:hover {
		box-shadow: 0 6px 3px #0000001b;

		.mat-icon {
			right: -1.2rem;
			font-size: 6.3rem;
			opacity: .15;
		}
	}
}