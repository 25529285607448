@import "../main/util/index.scss";

//GENERAL STYLES--------------------------------------------------------------->

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6
{
	color: $header_text_color;
}

a:link,
a:visited
{
	color: #688696;
	transition: all 0.5s ease;
}

a:active,
a:hover
{
	color: $pcode-color-accent;
}

a.primary:link,
a.primary:visited
{
	color: $pcode-color-primary;
	transition: all 0.5s ease;
}

a.primary:active,
a.primary:hover
{
	color: darken($pcode-color-primary, 13%);
}

a.accent:link,
a.accent:visited
{
	color: $pcode-color-accent;
	transition: all 0.5s ease;
}

a.accent:active,
a.accent:hover
{
	color: darken($pcode-color-accent, 13%);
}
//HELPER CLASSES--------------------------------------------------------------->
.app_primary_bg
{
	background-color: $pcode-color-primary !important;
}

.app_secondary_bg
{
	background-color: $pcode-color-accent !important;
}

.app_primary_lighten_bg
{
	background-color: lighten($pcode-color-primary, 5%) !important;
}

.app_primary_darken_bg
{
	background-color: darken($pcode-color-primary, 5%) !important;
}

.app_accent_bg
{
	background-color: $pcode-color-accent !important;
}
//----------------------------------------------------------------------------->
//LAYOUT
//----------------------------------------------------------------------------->

body
{
	background-color: $body_bg;
	color: $text_color;

	.mat-sidenav-container,
	p
	{
		color: $text_color;
	}

	#app_wrapper
	{
		//TOP NAVBAR--------------------------------------------------------------->
		#app_topnavbar-wrapper
		{
			background-color: $app_topnavbar-wrapper_bg;
			color: $app_topnavbar-wrapper_color;

			.navbar-nav
			{
				li a
				{
					color: $app_topnavbar-wrapper_color;
				}

				ul.dropdown-menu li a
				{
					color: #688696;
				}

				li ul li,
				li ul li a
				{
					color: #688696;
				}
			}

			.navbar-nav
			{
				> li > a
				{
					&:hover,
					&:hover
					{
						background: $app_topnavbar-wrapper_hover_color;
					}
				}

				> li > a
				{
					&:active,
					&:focus
					{
						background: transparent;
					}
				}
			}

			.avatar-menu .name,
			.select-menu .form-group .dropdownjs > input[readonly],
			.select-menu .form-group .dropdownjs::after
			{
				color: $app_topnavbar-wrapper_color;
			}

			.close-search
			{
				color: #688696 !important;
			}
		}

		#app_sidebar-left
		{
			background-color: $sidebar_menu_wrapper_bg;

			#app_main-menu-wrapper
			{
				//LOGO------------------------------------------------------------------->
				.nav-logo
				{
					background-color: $sidebar_menu_active_bg;
					box-shadow: none;

					.brand-text
					{
						color: #fff;
					}

					li a
					{
						color: $app_topnavbar-wrapper_color;
					}
				}
				//LEFT SIDEBAR--------------------------------------------------------->
				background-color: $sidebar_menu_wrapper_bg;
				//Profile Menu--------------------------------------------------------->
				.profile-menu
				{
					background: $sidebar_menu_wrapper_bg;

					ul.submenu
					{
						li
						{
							a,
							a:link
							{
								&:active,
								&:hover
								{
									background-color: $sidebar_menu_active_bg;
									color: $sidebar_menu_active_text_color;
								}
							}
						}
					}
				}

				.nav-pills > li > a
				{
					color: $sidebar_menu_text_color;
				}

				.nav .open > a,
				.nav .open > a:focus,
				.nav .open > a:hover,
				.nav-pills > li > a:active,
				.nav-pills > li > a:hover,
				.nav-pills > li.active > a
				{
					color: $sidebar_menu_active_text_color;
					background-color: $sidebar_menu_active_bg;
				}

				.nav > li > a:focus
				{
					background-color: $sidebar_menu_wrapper_bg;
				}

				.nav > li > a:hover
				{
					background-color: $sidebar_menu_active_bg;
				}

				.nav-pills > li.active > a,
				.nav-pills > li.active > a:focus,
				.nav-pills > li.active > a:hover
				{
					color: $sidebar_menu_active_text_color;
					background-color: $sidebar_menu_active_bg;
				}

				.nav .nav-sub
				{
					background-color: $sidebar_menu_active_bg;
				}

				.nav .nav-sub li > a
				{
					color: $sidebar_menu_text_color;
				}

				.nav .nav-sub li a:hover,
				.nav > li > .nav-sub li a:hover,
				.nav > li > .nav-sub li.active > a,
				.nav > li > .nav-sub li.active a:hover
				{
					color: $sidebar_menu_active_text_color;
				}

				.nav-pills > li,
				.nav-pills > .nav-dropdown
				{
					border-left: solid 3px transparent;
				}

				.nav-pills > li.active,
				.nav-pills > .nav-dropdown.open.active
				{
					border-left-color: $pcode-color-accent;
				}
			}
		}
		//RIGHT OVERLAY SIDEBAR-------------------------------------------------------->
		#app_sidebar-right
		{
			.sidebar-overlay
			{
			}
		}
		//PAGE HEADER----------------------------------------------------------------------->
		#content_wrapper
		{
			.content .header-wrapper
			{
				background-color: $page_header_bg;
				color: $page_header_text;

				h1
				{
					color: $page_header_text;
				}

				.actions.icons
				{
					a
					{
						color: #fff;
					}
				}
			}
		}
		//FOOTER----------------------------------------------------------------------->
		#footer_wrapper
		{
			background: $mais-interactive-900;
		}
	}
}
//----------------------------------------------------------------------------->
//Page Tenplates
//----------------------------------------------------------------------------->

.leftSidenav1-wrapper,
.rightSidenav1-wrapper
{
	.sidenav
	{
		#leftSidenav1,
		#rightSidenav1
		{
			.nav.nav-pills
			{
				.nav-link.btn.active
				{
					background-color: $pcode-color-accent;
					color: #ffffff;
					box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($pcode-color-accent, 0.2);
				}
			}
		}
	}
}
//----------------------------------------------------------------------------->
//Components
//----------------------------------------------------------------------------->
//Nav Tabs-------------------------------------------------------->

.nav-tabs li.active a:after
{
	background: $pcode-color-accent;
}
//Nav Pills-------------------------------------------------------->

.content
{
	.nav-pills > li.active > a
	{
		box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-accent, .2);
	}

	.nav-pills > li.active > a:focus,
	.nav-pills > li.active > a:hover
	{
		box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-accent, .2);
	}
}
//Modals
// Styles pulled from: main/modals/_bs-modals.scss

.modal
{
	.modal-header
	{
		color: #fff;
		background: $pcode-color-primary;

		.close
		{
			color: #fff;
		}
	}
}
//Cards
// Styles pulled from: main/cards/_cards.scss
.card
{
	&.card-primary
	{
		> .card-header,
		.card-block,
		.card-footer,
		.card-footer a,
		.card-footer a:visited,
		.card-title
		{
			color: #fff !important;
			background-color: $pcode-color-primary;
		}

		.card-block p
		{
			color: #fff !important;
		}

		.card-footer a:active,
		.card-footer a:hover
		{
			color: lighten($pcode-color-primary, 30%) !important;
		}
	}
}

.card-block.card-primary,
.card-footer.card-primary,
.card-header.card-primary,
.card-header.card-primary .actions > a,
.card-header.card-primary .actions > li > a,
.card-header.card-primary .card-title
{
	color: #fff;
	background-color: $pcode-color-primary;
	border-color: transparent;
}

.card
{
	&.card-accent
	{
		> .card-header,
		.card-block,
		.card-footer,
		.card-footer a,
		.card-footer a:visited,
		.card-title
		{
			color: #fff !important;
			background-color: $pcode-color-accent;
		}

		.card-block p
		{
			color: #fff !important;
		}

		.card-footer a:active,
		.card-footer a:hover
		{
			color: lighten($pcode-color-accent, 30%) !important;
		}
	}

	.card-block.card-accent,
	.card-footer.card-accent,
	.card-header.card-accent,
	.card-header.card-accent .actions > a,
	.card-header.card-accent .actions > li > a,
	.card-header.card-accent .card-title
	{
		color: #fff;
		background-color: $pcode-color-accent;
		border-color: transparent;
	}
}
//Context Menu

.context-menu__link:hover
{
	background-color: $pcode-color-accent;
	border-left: solid 6px darken($pcode-color-accent, 20%);
}
//Buttons

.btn,
.navbar .navbar-nav > li > a.btn
{
	&.btn-primary
	{
		@include btn-styles($pcode-color-primary);

		&.btn-flat
		{
			&.btn-primary:hover
			{
				background-color: rgba($pcode-color-primary, .1);
			}
		}
	}
}

.btn-primary-hover:hover
{
	background-color: $pcode-color-primary;
	box-shadow: 0 14px 26px -12px rgba($pcode-color-primary, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-primary, .2);
	color: #fff !important;
}

.btn,
.navbar .navbar-nav > li > a.btn
{
	&.btn-accent
	{
		@include btn-styles($pcode-color-accent);

		&.btn-flat
		{
			&.btn-accent:hover
			{
				background-color: rgba($pcode-color-accent, .1);
			}
		}
	}
}

.btn-accent-hover:hover
{
	background-color: $pcode-color-accent;
	box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-accent, .2);
	color: #fff !important;
}

.btn,
.navbar .navbar-nav > li > a.btn
{
	&.btn-warn
	{
		@include btn-styles($pcode-color-warn);
		color: #3e3e3e !important;

		&.btn-flat
		{
			&.btn-warn:hover
			{
				background-color: rgba($pcode-color-warn, .1);
			}
		}
	}
}

.btn-warn-hover:hover
{
	background-color: $pcode-color-warn;
	box-shadow: 0 14px 26px -12px rgba($pcode-color-warn, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-warn, .2);
	color: #fff !important;
}
//Drop Downs
// Styles pulled from: main/form-elements/_dropdowns.scss

.btn-primary + .dropdown-menu > li > a:active,
.btn-primary + .dropdown-menu > li > a:focus,
.btn-primary + .dropdown-menu > li > a:hover,
.btn-primary + .dropdown-menu li a:active,
.btn-primary + .dropdown-menu li a:focus,
.btn-primary + .dropdown-menu li a:hover,
.btn-primary.dropdown-menu > li > a:active,
.btn-primary.dropdown-menu > li > a:focus,
.btn-primary.dropdown-menu > li > a:hover,
.btn-primary.dropdown-menu li a:active,
.btn-primary.dropdown-menu li a:focus,
.btn-primary.dropdown-menu li a:hover,
.btn-primary.dropdown-menu li a:hover
{
	background-color: $pcode-color-primary;
	color: #fff !important;
	box-shadow: 0 14px 26px -12px rgba($pcode-color-primary, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($pcode-color-primary, 0.2);
}

.btn-primary.dropdown-menu li a:hover i
{
	box-shadow: none !important;
	color: #fff !important;
}

.btn-accent + .dropdown-menu > li > a:active,
.btn-accent + .dropdown-menu > li > a:focus,
.btn-accent + .dropdown-menu > li > a:hover,
.btn-accent + .dropdown-menu li a:active,
.btn-accent + .dropdown-menu li a:focus,
.btn-accent + .dropdown-menu li a:hover,
.btn-accent.dropdown-menu > li > a:active,
.btn-accent.dropdown-menu > li > a:focus,
.btn-accent.dropdown-menu > li > a:hover,
.btn-accent.dropdown-menu li a:active,
.btn-accent.dropdown-menu li a:focus,
.btn-accent.dropdown-menu li a:hover
{
	background-color: $pcode-color-accent;
	color: #fff !important;
	box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($pcode-color-accent, 0.2);
}
//Form wizard

#rootwizard
{
	.pager li > a,
	.pager li > span
	{
		background-color: $pcode-color-accent;
	}
}
//Toggles

.togglebutton
{
	label
	{
		input.toggle-primary[type=checkbox]:checked
		{
			+ .toggle
			{
				background-color: rgba($pcode-color-accent, (70*0.01));
			}

			+ .toggle:after
			{
				border-color: $pcode-color-accent;
			}

			+ .toggle:active:after
			{
				box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba($pcode-color-accent, (10*0.01));
			}
		}
	}
}
//Authentication Wrapper

#login-wrapper
{
	.card.active
	{
		#signup_wrapper
		{
			background: $pcode-color-accent;
		}
	}
}
//No ui slider plugin

.slider
{
	&.noUi-connect
	{
		background-color: $pcode-color-accent;
	}

	.noUi-handle
	{
		border-color: $pcode-color-accent;
	}

	&.slider-primary
	{
		& .noUi-connect,
		&.noUi-connect
		{
			background-color: $brand-primary;
		}

		.noUi-handle
		{
			border-color: $brand-primary;
		}
	}
}
//Pik a Day Calendar Plugin

.pika-button:hover
{
	background: $pcode-color-accent;
}

.dark-theme .is-today .pika-button
{
	color: $pcode-color-accent;
}
// Prism - Syntax Highlighter

code
{
	color: $pcode-color-accent;
	background-color: lighten($pcode-color-accent, 45%);
}
//Alerts

.swal2-modal
{
	.swal2-confirm
	{
		background-color: $pcode-color-accent !important;
		color: #fff !important;
		box-shadow: 0 2px 2px 0 rgba($pcode-color-accent, .14), 0 3px 1px -2px rgba($pcode-color-accent, .2), 0 1px 5px 0 rgba($pcode-color-accent, .12) !important;

		&:hover
		{
			box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-accent, .2);
		}
	}
}

.alertify .alert nav button.ok:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .dialog nav button.ok:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button)
{
	@include btn-styles($pcode-color-accent);
}
//Demo styles for icon page

.icon a:hover
{
	background-color: $pcode-color-accent;
}
//Badges

.nav-pills > .active > a > .badge,
list-group-item.active > .badge
{
	color: $pcode-color-accent;
}
//Nav Pills

.content
{
	.nav-pills > li.active > a
	{
		background-color: $pcode-color-accent;
	}

	.nav-pills > li.active > a:focus,
	.nav-pills > li.active > a:hover
	{
		background-color: $pcode-color-accent;
	}
}
//Preloaders

.preloader
{
	&.pls-pink .plc-path
	{
		stroke: $pcode-color-accent;
	}
}

.loader
{
	&.loader-pink:before
	{
		background-color: $pcode-color-accent;
	}
}
//Progressbars

.progress-bar
{
	background-color: $pcode-color-accent;
}
//Tabs Over Card

.tabs-over-card
{
	&.tabs-primary
	{
		.nav-tabs
		{
			background: $pcode-color-primary;
			box-shadow: 0 14px 26px -12px rgba($pcode-color-primary, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-primary, .2);
		}
	}

	&.tabs-accent
	{
		.nav-tabs
		{
			background: $pcode-color-accent;
			box-shadow: 0 14px 26px -12px rgba($pcode-color-accent, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba($pcode-color-accent, .2);
		}
	}
}

//Select
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value
{
	color: $text_color !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-disabled .mat-select-value,
.mat-select-arrow,
.mat-select-trigger
{
	color: lighten($text_color, 5%) !important;
}

//----------------------------------------------------------------------------->
//Video Plaer
//----------------------------------------------------------------------------->

.mejs__controls:not([style*="display:none"])
{
	background: rgba($pcode-color-primary, 1);
}
//----------------------------------------------------------------------------->
//Pages
//----------------------------------------------------------------------------->

#auth_wrapper
{
	background: $pcode-color-primary;
}
//----------------------------------------------------------------------------->
//Apps
//----------------------------------------------------------------------------->
//== Mail
//Pin toggle

.checkbox-pin label
{
	background: lighten($pcode-color-accent, 50%);
}

.checkbox-pin input:checked + label
{
	background: darken($pcode-color-accent, 5%);
}

.checkbox-pin input:checked + label:after,
.checkbox-pin input:checked + label:before
{
	background: $pcode-color-accent;
}

.checkbox-pin label:before
{
	color: $pcode-color-accent;
}
